import { format, parse } from 'date-fns';
import { Session as AuthSession } from 'next-auth';
import ReactDOMServer from 'react-dom/server';


export const NOT_AUTHORIZED = 'not_authorized'
export const NOT_FOUND = 'not_found'
export const SERVER_ERROR = 'server_error'


export const headersMyAtlas = (session?: AuthSession | null): any => ({
    'Ocp-Apim-Subscription-Key': process.env.NEXT_PUBLIC_SEL_API_KEY,
    'Accept': 'application/json',
    'Authorization': `Bearer ${session?.accessToken}`,
    'Atlas-Context': 'compte'
})

export const headersPublic = (): any => {
    return {
        'Ocp-Apim-Subscription-Key': process.env.NEXT_PUBLIC_SEL_PUBLIC_API_KEY,
        'Accept': 'application/json',
        'Atlas-Context': 'campus'
    }
}

export const headersCampus = (authSession?: AuthSession | null): any => {
    const headers: any = { 
        'Ocp-Apim-Subscription-Key': process.env.NEXT_PUBLIC_CAMPUS_API_KEY,
        'Accept': 'application/json',
    }
    if (authSession && authSession.accessToken) {
        headers['Authorization'] = `Bearer ${authSession.accessToken}`
    }
    return headers
}

const refDate = new Date()

export const parseAPIDate = (s: string) => s ? parse(s, 'yyyy-MM-dd', refDate) : null

export const pathify = (s: string) => s.toLowerCase().normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .replace(/\s(le|la|les|a|aux|un|une|de|du|et)\s/, ' ')
    .replace(/\s(le|la|les|a|aux|un|une|de|du|et)\s/, ' ')
    .replace(/\s(l'|l'|d'|d')/, ' ')
    .replace(/[^a-z0-9]/gmi, ' ')
    .replace(/\s+/g, '-')

export const formatDate = (d?: Date | null) => d ? format(d, 'dd/MM/yyyy') : ''
export const formatAPIDate = (d?: Date | null) => d ? format(d, 'yyyy-MM-dd') : null

export const formatMoney = (d?: number) => d?.toLocaleString('fr-FR', {
    currency: 'EUR',
    style: 'currency',
    roundingMode: "halfCeil"
} as Intl.NumberFormatOptions).replace(',00','')

interface SearchParams {
    [key: string]: string | string[] | null | undefined
}

export function toSearchParams(params: SearchParams): URLSearchParams {
    return setSearchParams(new URLSearchParams(), params)
}

export function toSearchString(params: SearchParams): string {
    return toSearchParams(params).toString()
}

export function setSearchParams(urlParams: URLSearchParams, params: SearchParams): URLSearchParams {
    for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
            urlParams.delete(key)
            for (const item of (value as string[]))
                urlParams.append(key, item)
        }
        else if (value !== null && value !== undefined) {
            urlParams.set(key, value)
        }
    }
    return urlParams
}

export const jsxToString = (jsx: JSX.Element, length = 160) => {
    return truncate(ReactDOMServer.renderToStaticMarkup(jsx).replace(/<[^>]+>/g, ''), length)
}


export const htmlToString = (html?: string, length = 160) => {
    return truncate(html?.replace(/<[^>]+>/g, '') ?? '', length)
}


export const truncate = (text?: string, length = 160) => {
    let s = (text ?? '').trim()
    if (s.length <= length)
        return text
    
    s = s.substr(0, length - 1)
    const tokens = s.split(/\s\S*$/g)
    if (tokens.length > 0)
        s = tokens[0]
    
    return s + "&hellip;"
}