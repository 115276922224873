import classNames from "classnames";
import { AtlasIcon } from "../../image";

export interface AtlasEnvProps {
  /**
   * Le nom de l'environnement
   */
  env: "dev" | "recette" | "staging" | "uat";
  /**
   * Une URL de destination (par exemple un environnement alternatif)
   */
  href?: string;
}

/**
 * Un bandeau affichant l'environnement actuel (ne pas utiliser en production !)
 */
export function AtlasEnv(props: AtlasEnvProps) {
  const className = classNames("atlas-env", `atlas-env--${props.env}`);

  return props.href ? (
    <a className={className} href={props.href}>
      {props.env}
      <AtlasIcon size="xs" name="reload" />
    </a>
  ) : (
    <div className={className}>{props.env}</div>
  );
}
