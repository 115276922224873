import classNames from "classnames";
import { AtlasInfoType } from "../AtlasInfo";

export interface AtlasRibbonProps {
  /**
   * Le contenu sur lequel est placé le ruban
   */
  children: React.ReactNode;
  /**
   * Le label du ruban.
   */
  label: string;
  /**
   * Le type d'information (info par défaut)
   */
  type?: AtlasInfoType;
}

/**
 * Englobe un composant sur lequel sera placé un ruban d'information permettant
 * de renseigner sur l'état de l'élément (disponible, bientôt indisponible...).
 * Attention ! Il faut s'assurer que la présence du bandeau n'entrave jamais la
 * lisibilité du contenu lui-même.
 */
export function AtlasRibbon(props: AtlasRibbonProps) {
  return (
    <div className="atlas-ribbon">
      {props.children}

      <div
        className={classNames(
          "atlas-ribbon__label",
          `u-atlas-color-${props.type ?? "info"}`
        )}
      >
        <div className="atlas-ribbon__labelInner">{props.label}</div>
      </div>
    </div>
  );
}
